const PageSpec = {
    APP: {
        //================//
        //
        // Home Page
        //
        //================//
        LANDING: {
            path: '/',
            name: 'landing',
            meta: {
                title: 'page.menu-title.detail.landing',
                permissions: []
            },
            component: () => import('@/views/pages/Landing.vue')
        },
        //================//
        //
        // Blog Page
        //
        //================//
        BLOG: {
            path: '/blog',
            name: 'blog',
            meta: {
                title: 'page.menu-title.detail.blog',
                permissions: []
            },
            component: () => import('@/views/pages/blog/Blog.vue')
        },
        BLOGPOSTS: {
            path: '/blog/:id',
            name: 'blogposts',
            meta: {
                title: 'page.menu-title.detail.blog',
                permissions: []
            },
            component: () => import('@/views/pages/blog/BlogPost.vue')
        },
        //================//
        //
        // Elements
        //
        //================//
        WIND: {
            path: '/wind',
            name: 'wind',
            meta: {
                title: 'page.menu-title.detail.wind',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Wind.vue')
        },
        EARTH: {
            path: '/earth',
            name: 'earth',
            meta: {
                title: 'page.menu-title.detail.earth',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Earth.vue')
        },
        WATER: {
            path: '/water',
            name: 'water',
            meta: {
                title: 'page.menu-title.detail.water',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Water.vue')
        },
        FIRE: {
            path: '/fire',
            name: 'fire',
            meta: {
                title: 'page.menu-title.detail.fire',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Fire.vue')
        },
        ELECTRIC: {
            path: '/electric',
            name: 'electric',
            meta: {
                title: 'page.menu-title.detail.electric',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Electric.vue')
        },
        ICE: {
            path: '/ice',
            name: 'ice',
            meta: {
                title: 'page.menu-title.detail.ice',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Ice.vue')
        },
        SHADOW: {
            path: '/shadow',
            name: 'shadow',
            meta: {
                title: 'page.menu-title.detail.shadow',
                permissions: []
            },
            component: () => import('@/views/pages/houses/Shadow.vue')
        },

        //================//
        //
        // Company
        //
        //================//

        ABOUT: {
            path: '/about',
            name: 'about',
            meta: {
                title: 'page.menu-title.detail.about',
                permissions: []
            },
            component: () => import('@/views/pages/company/About.vue')
        },

        //================//
        //
        // Community
        //
        //================//

        FAQ: {
            path: '/faq',
            name: 'faq',
            meta: {
                title: 'page.menu-title.detail.faq',
                permissions: []
            },
            component: () => import('@/views/pages/community/FAQ.vue')
        },

        //================//
        //
        // Legal
        //
        //================//

        CONSTITUTION: {
            path: '/constitution',
            name: 'constitution',
            meta: {
                title: 'page.menu-title.detail.constitution',
                permissions: []
            },
            component: () => import('@/views/pages/legal/Constitution.vue')
        },

        PRIVACYPOLICY: {
            path: '/privacy-policy',
            name: 'privacypolicy',
            meta: {
                title: 'page.menu-title.detail.privacypolicy',
                permissions: []
            },
            component: () => import('@/views/pages/legal/PrivacyPolicy.vue')
        },

        TERMSOFSERVICE: {
            path: '/terms-of-service',
            name: 'termsofservice',
            meta: {
                title: 'page.menu-title.detail.termsofservice',
                permissions: []
            },
            component: () => import('@/views/pages/legal/TermsOfService.vue')
        },

        //================//
        //
        // Quintinity
        //
        //================//

        AVATCH: {
            path: '/avatch',
            name: 'avatch',
            meta: {
                title: 'page.menu-title.detail.avatch',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Avatch.vue')
        },
        NECTARINE: {
            path: '/nectarine',
            name: 'nectarine',
            meta: {
                title: 'page.menu-title.detail.avatch',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Avatch.vue')
        },
        BIVEECH: {
            path: '/biveech',
            name: 'biveech',
            meta: {
                title: 'page.menu-title.detail.biveech',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Biveech.vue')
        },
        KIWI: {
            path: '/kiwi',
            name: 'kiwi',
            meta: {
                title: 'page.menu-title.detail.biveech',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Biveech.vue')
        },
        CEALTEAN: {
            path: '/cealtean',
            name: 'cealtean',
            meta: {
                title: 'page.menu-title.detail.cealtean',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Cealtean.vue')
        },
        HONEYDEW: {
            path: '/honeydew',
            name: 'honeydew',
            meta: {
                title: 'page.menu-title.detail.cealtean',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Cealtean.vue')
        },
        DEVAUNTT: {
            path: '/devauntt',
            name: 'devauntt',
            meta: {
                title: 'page.menu-title.detail.devauntt',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Devauntt.vue')
        },
        DRAGONFRUIT: {
            path: '/dragonfruit',
            name: 'dragonfruit',
            meta: {
                title: 'page.menu-title.detail.devauntt',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Devauntt.vue')
        },
        ECCHALE: {
            path: '/ecchale',
            name: 'ecchale',
            meta: {
                title: 'page.menu-title.detail.ecchale',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ecchale.vue')
        },
        LIME: {
            path: '/lime',
            name: 'lime',
            meta: {
                title: 'page.menu-title.detail.ecchale',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ecchale.vue')
        },
        FYULLE: {
            path: '/fyulle',
            name: 'fyulle',
            meta: {
                title: 'page.menu-title.detail.fyulle',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Fyulle.vue')
        },
        PINEAPPLE: {
            path: '/pineapple',
            name: 'pineapple',
            meta: {
                title: 'page.menu-title.detail.fyulle',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Fyulle.vue')
        },
        HEUBANE: {
            path: '/heubane',
            name: 'heubane',
            meta: {
                title: 'page.menu-title.detail.heubane',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Heubane.vue')
        },
        AGAVE: {
            path: '/agave',
            name: 'agave',
            meta: {
                title: 'page.menu-title.detail.heubane',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Heubane.vue')
        },
        IKKLEEN: {
            path: '/ikkleen',
            name: 'ikkleen',
            meta: {
                title: 'page.menu-title.detail.ikkleen',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ikkleen.vue')
        },
        PEACH: {
            path: '/peach',
            name: 'peach',
            meta: {
                title: 'page.menu-title.detail.ikkleen',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ikkleen.vue')
        },
        KYCHAIN: {
            path: '/kychain',
            name: 'kychain',
            meta: {
                title: 'page.menu-title.detail.kychain',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Kychain.vue')
        },
        RASPBERRY: {
            path: '/raspberry',
            name: 'raspberry',
            meta: {
                title: 'page.menu-title.detail.kychain',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Kychain.vue')
        },
        LYHVIEN: {
            path: '/lyhvien',
            name: 'lyhvien',
            meta: {
                title: 'page.menu-title.detail.lyhvien',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Lyhvien.vue')
        },
        PEAR: {
            path: '/pear',
            name: 'pear',
            meta: {
                title: 'page.menu-title.detail.lyhvien',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Lyhvien.vue')
        },
        MEAACUB: {
            path: '/meaacub',
            name: 'meaacub',
            meta: {
                title: 'page.menu-title.detail.meaacub',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Meaacub.vue')
        },
        GRAPE: {
            path: '/grape',
            name: 'grape',
            meta: {
                title: 'page.menu-title.detail.meaacub',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Meaacub.vue')
        },
        OGYANN: {
            path: '/ogyann',
            name: 'ogyann',
            meta: {
                title: 'page.menu-title.detail.ogyann',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ogyann.vue')
        },
        WATERMELON: {
            path: '/watermelon',
            name: 'watermelon',
            meta: {
                title: 'page.menu-title.detail.ogyann',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Ogyann.vue')
        },
        PEZZEAN: {
            path: '/pezzean',
            name: 'pezzean',
            meta: {
                title: 'page.menu-title.detail.pezzean',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Pezzean.vue')
        },
        LEMON: {
            path: '/lemon',
            name: 'lemon',
            meta: {
                title: 'page.menu-title.detail.pezzean',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Pezzean.vue')
        },
        QOUVENA: {
            path: '/qouvena',
            name: 'qouvena',
            meta: {
                title: 'page.menu-title.detail.qouvena',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Qouvena.vue')
        },
        PLUM: {
            path: '/plum',
            name: 'plum',
            meta: {
                title: 'page.menu-title.detail.qouvena',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Qouvena.vue')
        },
        RAHHVEN: {
            path: '/rahhven',
            name: 'rahhven',
            meta: {
                title: 'page.menu-title.detail.rahhven',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Rahhven.vue')
        },
        BANANA: {
            path: '/banana',
            name: 'banana',
            meta: {
                title: 'page.menu-title.detail.rahhven',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Rahhven.vue')
        },
        SCIIABE: {
            path: '/sciiabe',
            name: 'sciiabe',
            meta: {
                title: 'page.menu-title.detail.sciiabe',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Sciiabe.vue')
        },
        POMEGRANATE: {
            path: '/pomegranate',
            name: 'pomegranate',
            meta: {
                title: 'page.menu-title.detail.sciiabe',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Sciiabe.vue')
        },
        TARAAN: {
            path: '/taraan',
            name: 'taraan',
            meta: {
                title: 'page.menu-title.detail.taraan',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Taraan.vue')
        },
        BLUEBERRY: {
            path: '/blueberry',
            name: 'blueberry',
            meta: {
                title: 'page.menu-title.detail.taraan',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Taraan.vue')
        },
        UAATEN: {
            path: '/uaaten',
            name: 'uaaten',
            meta: {
                title: 'page.menu-title.detail.uaaten',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Uaaten.vue')
        },
        BLACKBERRY: {
            path: '/blackberry',
            name: 'blackberry',
            meta: {
                title: 'page.menu-title.detail.uaaten',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Uaaten.vue')
        },
        VACIIAN: {
            path: '/vaciian',
            name: 'vaciian',
            meta: {
                title: 'page.menu-title.detail.vaciian',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Vaciian.vue')
        },
        CRANBERRY: {
            path: '/cranberry',
            name: 'cranberry',
            meta: {
                title: 'page.menu-title.detail.vaciian',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Vaciian.vue')
        },
        XERTAV: {
            path: '/xertav',
            name: 'xertav',
            meta: {
                title: 'page.menu-title.detail.xertav',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Xertav.vue')
        },
        ACAI: {
            path: '/acai',
            name: 'acai',
            meta: {
                title: 'page.menu-title.detail.xertav',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Xertav.vue')
        },
        YROTCEN: {
            path: '/yrotcen',
            name: 'yrotcen',
            meta: {
                title: 'page.menu-title.detail.yrotcen',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Yrotcen.vue')
        },
        GRAPEFRUIT: {
            path: '/grapefruit',
            name: 'grapefruit',
            meta: {
                title: 'page.menu-title.detail.yrotcen',
                permissions: []
            },
            component: () => import('@/views/pages/quintinity/Yrotcen.vue')
        },

        //================//
        //
        //
        //
        //================//

        PAGE: {}
    }
};

export default PageSpec;
