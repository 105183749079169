import logger from '@/common/logger';
import { translate } from '@/locales';
import { default as page, default as PageSpec } from '@/router/page';
import useAuthStore from '@/store/authStore';
import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        page.APP.LANDING,
        page.APP.BLOG,
        page.APP.BLOGPOSTS,
        page.APP.WIND,
        page.APP.EARTH,
        page.APP.WATER,
        page.APP.FIRE,
        page.APP.ELECTRIC,
        page.APP.ICE,
        page.APP.SHADOW,
        page.APP.ABOUT,
        page.APP.FAQ,
        page.APP.CONSTITUTION,
        page.APP.PRIVACYPOLICY,
        page.APP.TERMSOFSERVICE,
        page.APP.AVATCH,
        page.APP.NECTARINE,
        page.APP.BIVEECH,
        page.APP.KIWI,
        page.APP.CEALTEAN,
        page.APP.HONEYDEW,
        page.APP.DEVAUNTT,
        page.APP.DRAGONFRUIT,
        page.APP.ECCHALE,
        page.APP.LIME,
        page.APP.FYULLE,
        page.APP.PINEAPPLE,
        page.APP.HEUBANE,
        page.APP.AGAVE,
        page.APP.IKKLEEN,
        page.APP.PEACH,
        page.APP.KYCHAIN,
        page.APP.RASPBERRY,
        page.APP.LYHVIEN,
        page.APP.PEAR,
        page.APP.MEAACUB,
        page.APP.GRAPE,
        page.APP.OGYANN,
        page.APP.WATERMELON,
        page.APP.PEZZEAN,
        page.APP.LEMON,
        page.APP.QOUVENA,
        page.APP.PLUM,
        page.APP.RAHHVEN,
        page.APP.BANANA,
        page.APP.SCIIABE,
        page.APP.POMEGRANATE,
        page.APP.TARAAN,
        page.APP.BLUEBERRY,
        page.APP.UAATEN,
        page.APP.BLACKBERRY,
        page.APP.VACIIAN,
        page.APP.CRANBERRY,
        page.APP.XERTAV,
        page.APP.ACAI,
        page.APP.YROTCEN,
        page.APP.GRAPEFRUIT,
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: () => import('@/views/pages/NotFound.vue') }
    ]
});

router.beforeEach(async (to, from, next) => {
    next();
    // if (whiteListUrl.includes(to.name)) {
    //     next();
    // } else if (isAuthenticated()) {
    //     await redirectIfValid(to, from, next);
    // } else {
    //     await redirectIfInvalid(to, from, next);
    // }
});

function isAuthenticated() {
    const authStore = useAuthStore();
    logger.debug(authStore.isAuthenticated);
    return authStore.isAuthenticated;
}

async function redirectIfInvalid(to, from, next) {
    if (to.path !== PageSpec.AUTH.LOGIN.path) {
        await next({
            name: PageSpec.AUTH.LOGIN.name
        });
    } else {
        next();
    }
}

async function redirectIfValid(to, from, next) {
    if (to.name === PageSpec.AUTH.LOGIN.name) {
        await next({ name: PageSpec.APP.DASHBOARD.name });
    } else if (hasPermission(to.meta.permissions ?? [])) {
        next();
    } else {
        await next({ name: PageSpec.ACCESS.DENIED.name });
    }
}

function hasPermission(permissions) {
    const authStore = useAuthStore();
    return permissions.length === 0 || containsAny(permissions, authStore.getPermissions);
}

function containsAny(arr1, arr2) {
    return arr1.some((element) => {
        return arr2.indexOf(element) !== -1;
    });
}

router.afterEach(async (to) => {
    await nextTick(() => {
        const pageTitle = translate(to.meta.title ?? to.name.toUpperCase() + '');
        document.title = pageTitle;
    });
});

export default router;
